import Head from '../Shared/Head';
import Navbar from '../Shared/Navbar';
import {NavbarProvider} from '../Contexts/navbar-context';
import React from 'react';
import mariage1 from '../assets/mariage1.jpg';
import mariage2 from '../assets/mariage2.jpg';
import mariage3 from '../assets/mariage3.jpg';
import mariage4 from '../assets/mariage4.jpg';

function WeddingPage() {
  return (
    <React.Fragment>
      <Head title={'Ouverture de bal de mariage'} />
      <NavbarProvider value="dark">
        <Navbar/>
      </NavbarProvider>
      
      <div className="wedding-container">
        <h2>Ouverture de bal de mariage</h2>
        <p>L’ouverture du bal lors d’un mariage est un moment attendu et indispensable. Elle est même cruciale pour le début de votre soirée. Il s’agit de la soigner et de mettre tous vos convives dans une ambiance festive. Le Danc’ In vous aidera à trouver la combinaison parfaite pour que l’ouverture de bal de votre mariage soit, selon votre choix, émouvante, romantique ou festive. Une musique qui vous ressemble, une chorégraphie adaptée afin d’éblouir et surprendre vos invités.</p>
        <label>N’hésitez pas à nous contacter, nous répondrons à vos attentes. Trois forfaits s’offrent à vous selon votre demande :</label>
        <div className="row">
          <div>
            <h6>Forfait 3 heures</h6>
            <span>165€ (55€/h)</span>
          </div>
          <div>
            <h6>Forfait 6 heures</h6>
            <span>300€ (50€/h)</span>
          </div>
          <div>
            <h6>Forfait 9 heures</h6>
            <span>405€ (45€/h)</span>
          </div>
        </div>

        <div className="columns">
            <div className="column">
                <div className="card">
                    <div className="card-image">
                        <figure className="image is-4by3">
                            <img src={mariage1} alt="" />
                        </figure>
                    </div>
                </div>
            </div>

            <div className="column">
                <div className="card">
                    <div className="card-image">
                        <figure className="image is-4by3">
                            <img src={mariage2} alt="" />
                        </figure>
                    </div>
                </div>
            </div>

            <div className="column">
                <div className="card">
                    <div className="card-image">
                        <figure className="image is-4by3">
                            <img src={mariage3} alt="" />
                        </figure>
                    </div>
                </div>
            </div>

            <div className="column">
                <div className="card">
                    <div className="card-image">
                        <figure className="image is-4by3">
                            <img src={mariage4} alt="" />
                        </figure>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default WeddingPage;
