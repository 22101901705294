import Head from '../Shared/Head';
import Navbar from '../Shared/Navbar';
import {NavbarProvider} from '../Contexts/navbar-context';
import React from 'react';
import TrophiesCard from '../Utils/Cards/TrophiesCard';
import {teachersContent} from '../Datas/teachers';

function TeacherPage({match}) {
  const teacher = teachersContent.filter(x => x[match.params.professeur])[0][match.params.professeur];
  return (
    <React.Fragment>
      <Head title={teacher.name} />
      <NavbarProvider value="dark">
        <Navbar/>
      </NavbarProvider>
      
      <div className="teacher-container">
        <div className="columns" style={{minHeight: '100vh'}}>
          <div className="column is-full-mobile is-half-desktop teacher-img" style={{backgroundImage: `url(${teacher.img})`}}>
            <div className="header-block">
              <span>{teacher.dances}</span>
              <h1>{teacher.name}</h1>
            </div>
          </div>
          <div className="column is-full-mobile is-half-desktop teacher-infos">
            <h3>Parcours</h3>
            {
              teacher.trophies.length > 0 ?
              <div className="trophies">
                <TrophiesCard trophies={teacher.trophies} />
              </div>
              : null
            }

            {teacher.text.map((t, index) => <p key={index} className={index === 0 ? 'mt-2-2' : 'mt-2'}>{t}</p>)}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default TeacherPage;
