import React from 'react';

function PriceChildCard({name, mainPrice, img, size}) {
  return (
    <div className="price-card" style={{ minHeight: `${size}px` }}>
      <h2>{name}</h2>
      <img src={img} alt="" />
      <div>
        <p>Tarif</p>
        <span>{mainPrice} €</span>
      </div>
    </div>
  );
}

export default PriceChildCard;