import React from 'react';
import Head from '../Shared/Head';
import Navbar from '../Shared/Navbar';
import {NavbarProvider} from '../Contexts/navbar-context'
import HomeTeachers from '../Sections/Home/HomeTeachers';

function TeachersPage() {
  return (
    <React.Fragment>
      <Head title={'Professeurs'} />
      <NavbarProvider value="dark">
        <Navbar/>
      </NavbarProvider>
      
      <div style={{paddingTop: '65px'}}>
        <HomeTeachers />
      </div>
    </React.Fragment>
  );
}

export default TeachersPage;
