import React, { Component } from 'react';

import {Link} from "react-router-dom";
import Slider from 'react-slick';
import { withFirebase } from '../../Firebase';

const settings = {
  customPaging: function(i) {
    return (
      <span></span>
    );
  },
  dotsClass: 'slick-dots slick-thumb',
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 5000,
  className: 'home-slider',
};

class HomeSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      slides: [],
    }
  }

  componentDidMount() {
    this.setState({ loading: true });
    
    this.props.firebase.getSlides().then(snapshot => {
      let array = [];
      snapshot.forEach((doc) => {
        array.push(doc.data());
      });
      this.setState({ slides: array, loading: false });
    }).catch((error) => {
      console.log("Error getting documents: ", error);
    });
  }

  render() {
    return (
      <Slider {...settings}>
        {
          this.state.slides.map((slide) => {
            return (
              <Link to="/evenements" key={slide.id}>
                <div>
                  <img src={slide.imageDesktop} className="img-slider desktop" alt="Ecole de danse Toulouse" />
                  <img src={slide.imageMobile} className="img-slider mobile" alt="Ecole de danse Toulouse" />
                </div>
              </Link>
            )
          })
        }
      </Slider>
    );
  }
}

export default withFirebase(HomeSlider);
