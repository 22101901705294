import { Link } from 'react-router-dom';
import React from 'react';

function TeacherCard({img, name, slug, dances}) {
  return (
    <div className="teacher-card">
      <Link to={`/professeurs/${slug}`}>
        <img src={img} alt={`${name} professeur de ${dances}`} />
        <h5>{name}</h5>
        <h6>{dances}</h6>
        <button className="bottom-card-btn">
          VOIR PLUS
        </button>
      </Link>
    </div>
  );
}

export default TeacherCard;
