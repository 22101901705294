import React from 'react';

function RoomCard({img, name}) {
  return (
    <div className="card">
      <div className="card-image">
        <figure className="image is-4by3">
          <img src={img} alt="" />
        </figure>
      </div>
      <div className="card-content">    
        <div className="content">{name}</div>
      </div>
    </div>
  );
}

export default RoomCard;