import ContactCard from "../Utils/Cards/ContactCard";
import ContactFormCard from "../Utils/Cards/ContactFormCard";
import Head from "../Shared/Head";
import Navbar from "../Shared/Navbar";
import { NavbarProvider } from "../Contexts/navbar-context";
import React from "react";
import shape from "../assets/contact-shape.png";

function ContactPage() {
  return (
    <React.Fragment>
      <Head title={"Contact"} />
      <NavbarProvider value="transparent">
        <Navbar />
      </NavbarProvider>
      <div className="contact-container">
        <div className="splitted-part left"></div>
        <div className="splitted-part right">
          <img src={shape} alt="" />
        </div>
        <ContactCard />
      </div>
      <div className="contact-container contact-container-form">
        <div className="splitted-part left"></div>
        <ContactFormCard />
      </div>
    </React.Fragment>
  );
}

export default ContactPage;
