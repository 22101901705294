import './sass/app.scss';

import * as serviceWorker from './serviceWorker';

import { BrowserRouter, Route } from 'react-router-dom';
import Firebase, { FirebaseContext } from './Firebase';

import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <BrowserRouter>
      <Route path="/" component={ App }/>
    </BrowserRouter>
  </FirebaseContext.Provider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
