import CourseCard from '../../Utils/Cards/CourseCard';
import React from 'react';
import afroImg from '../../assets/afro.jpg';
import afrovibeImg from '../../assets/afrovibe.jpg';
import bachataImg from '../../assets/bachata.jpg';
import dancehallImg from '../../assets/dancehall.jpg';
import danseOrientaleImg from '../../assets/danseOrientale.png';
import heelsImg from '../../assets/heels.JPG';
import kizombaImg from '../../assets/kizomba.jpg';
import ladyStyleImg from '../../assets/lady-style.jpg';
import pilatesImg from '../../assets/pilates.jpg';
import reggaetonImg from '../../assets/reggaeton.jpg';
import rockImg from '../../assets/rock.jpg';
import salonImg from '../../assets/salon.jpg';
import salsaImg from '../../assets/salsa.jpg';
import streetJazzImg from '../../assets/street-jazz.jpg';
import tangoImg from '../../assets/tango.jpg';

const courses = [
  {name: 'salsa', img: salsaImg},
  {name: 'rock, lindy hop', img: rockImg},
  {name: 'bachata', img: bachataImg},
  {name: 'kizomba', img: kizombaImg},
  {name: 'tango argentin', img: tangoImg},
  {name: 'danses de salon', img: salonImg},
  {name: 'reggaeton', img: reggaetonImg},
  {name: 'street jazz', img: streetJazzImg},
  {name: 'dancehall', img: dancehallImg},
  {name: 'danse orientale', img: danseOrientaleImg},
  {name: 'lady styling latino', img: ladyStyleImg},
  {name: 'afro vibe', img: afrovibeImg},
  {name: 'heels', img: heelsImg},
  {name: 'pilates', img: pilatesImg},
  {name: 'afro', img: afroImg},
];

function HomeCourses() {
  return (
    <div className="home-courses">
      {/* <h2 className="main-font">Les danses de l'ecole</h2> */}
      <div className="position">
        {courses.map(course => <CourseCard key={course.name} name={course.name} img={course.img} />)}
      </div>
    </div>
  );
}

export default HomeCourses;
