import React from 'react';
import pdf from '../assets/Fiche-dinscription.pdf';
import pdf2 from '../assets/Fiche-dinscription-enfant.pdf';
import reglement from '../assets/REGLEMENT_INTERIEUR_2024-2025.pdf';

function hideRegisterModal() {
  document.getElementsByClassName('r-modal')[0].classList.remove('is-active');
  document.getElementsByTagName('html')[0].classList.remove('is-clipped');
}

function RegisterModal() {
  return (
    <div className="modal r-modal">
      <div className="modal-background"></div>
      <div className="modal-content register-modal">
        <h2>S'inscrire au Danc'in</h2>
        <p>Pour vous inscrire au Danc'In pour la saison 2022-2023, vous trouverez la fiche d'inscription et le règlement d'intérieur ci-dessous à compléter.<br/>Pour valider votre inscription, apportez le dossier complet au Danc'In.</p>
        <h6>Règlement d'intérieur</h6>
        <button className="pdf">
          <a target="_blank" rel="noopener noreferrer" href={reglement}>règlement d'intérieur</a>
        </button>
        <span>Téléchargement d'un fichier au format pdf.</span>
        <h6>Fiches d'inscription</h6>
        <button className="pdf">
          <a target="_blank" rel="noopener noreferrer" href={pdf}>fiche d'inscription adulte</a>
        </button>
        <button className="pdf" style={{marginTop: '.5rem'}}>
          <a target="_blank" rel="noopener noreferrer" href={pdf2}>fiche d'inscription enfant et ado</a>
        </button>
        <span>Téléchargement d'un fichier au format pdf.</span>
        <button onClick={hideRegisterModal} className="modal-close is-large" aria-label="close"></button>
      </div>
    </div>
  );
}

export default RegisterModal;
