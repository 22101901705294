import React from 'react';

const icons = [
  'fas fa-trophy',
  'fas fa-award',
  'fas fa-medal',
];

function TrophiesCard({trophies}) {
  return (
    <div className="trophies-card card">
      <div className="card-content">
        <div className="columns">
          {
            trophies.map((trophy, index) => {
              return (
                <div key={index} className="column is-4">
                  <i className={icons[Math.floor(Math.random() * Math.floor(3))]}></i>
                  <span>{trophy}</span>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  );
}

export default TrophiesCard;
