import React from 'react';

export const NavbarThemes = {
    light: {
        background: 'white',
        color: 'black'
    },
    transparent: {
        background: 'transparent',
        color: 'white'
    },
    dark: {
        background: 'black',
        color: 'white'
    }
};

export const NavbarContext = React.createContext(NavbarThemes.light);
export const NavbarProvider = NavbarContext.Provider;
export const NavbarConsumer = NavbarContext.Consumer;
