import React from 'react';
import home_1 from '../../assets/accueil_1.png';
import home_2 from '../../assets/accueil_2.png';
import home_3 from '../../assets/accueil_3.png';
import logo from '../../assets/logo-simple.png';

function HomeHistory() {
  return (
    <div className="home-history">
      <div className="home-intro">
        <img className="logo" src={logo} alt="Logo de danse du Danc'in" />
        <div className="intro">
          <p><strong>Ecole de danse pluridisciplinaire, cours collectifs, cours particuliers, préparation à la compétition, ouverture de bal de mariage</strong></p>
          <p className="mb-2">Le Danc’In, votre école de danse à Toulouse : un lieu emblématique ... à redécouvrir....</p>
          <p>Dancing, puis restaurant de la Roseraie, cet etablissement voit le jour dans les années 1940.</p>
          <p>Aujourd'hui, école de danse, Le Danc'In, inauguré en septembre 2019, après une importante rénovation, sous la houlette de Roland Salerno, se métamorphose pour vous accueillir dans un cadre années 70 où vous évoluerez sur 400 m2 de pistes parquetées réparties en trois salles climatisées.</p>
          <p>Composé d'une équipe de professionnels, jeune et dynamique, Le Danc'In, attaché à la diversité de la danse, vous propose une déclinaison de cours adaptée à plusieurs niveaux : enfants, ados et adultes en solo ou en couple.</p>
          <p>C'est lors des soirées dansantes que vous ferez vos armes, défierez votre timidité, et testerez nos trois ambiances musicales.</p>
          <p>Pour vous initier ou vous perfectionner, pour partager des moments de convivialité, <strong>Le Danc’In est « The place to be ».</strong></p>
          <p>Curieux d'en savoir plus ? N'hésitez pas à naviguer sur notre site !</p>
        </div>
      </div>
      <div className="home-container">
        <div className="home-1">
          <img src={home_1} style={{width: '90%'}} alt="Danses de couple adultes" />
          <div>
            <label>Danses de couple adultes</label>
            <ul>
              <li>Salsa</li>
              <li>Bachata</li>
              <li>Kizomba</li>
              <li>Rock</li>
              <li>Danse de Salon</li>
              <li>Tango Argentin</li>
            </ul>
          </div>
        </div>

        <div className="home-2">
          <img src={home_2} style={{width: '65%'}} alt="Danses solo" />
          <div>
            <label>Danses solo</label>
            <ul>
              <li>Reggaeton</li>
              <li>Danse Orientale</li>
              <li>Dancehall</li>
              <li>Street Jazz</li>
              <li>Afro Vibe</li>
              <li>Lady Styling Latino</li>
              <li>Heels</li>
              <li>Afro Dance</li>
              <li>Pilates</li>
            </ul>
          </div>
        </div>

        <div className="home-3">
          <img src={home_3} style={{width: '72%'}} alt="Danses enfants et ados" />
          <div>
            <label>Danses enfants et ados</label>
            <ul>
              <li>Baby Street Jazz</li>
              <li>Dancehall</li>
              <li>Street Jazz</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeHistory;
