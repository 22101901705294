import React from 'react';

function HomePresentation() {
  return (
    <div className="home-presentation">
      <div className="columns squares">
        <div className="column">
          <div className="square">
            <div className="square-border"></div>
          </div>
        </div>
        <div className="column">
          <div className="main square">
          <div className="square-border"></div>
          </div>
        </div>
        <div className="column">
          <div className="square">
            <div className="square-border"></div>
          </div>
        </div>
      </div>

      <div className="columns bt">
        <div className="column">
          <h5>Apprendre en s'amusant</h5>
          <p>Vous appréhenderez la danse, sans contraintes, avec une méthode progressive où le plaisir reste au centre de l'apprentissage. Vous passerez un bon moment de détente où se mêlent échange et partage en présence de professeurs à l'écoute.</p>
        </div>

        <div className="column">
          <h5>Le Danc'in, le lieu qui va changer votre vie</h5>
          <p>Vous allez expérimenter la danse par le biais d'une immersion dans un groupe sympathique, partageant le même centre d'intérêt, désireux de progresser sans pression, et surtout dont la devise repose sur : <strong>du fun, encore du fun et toujours du fun !</strong></p>
        </div>

        <div className="column">
          <h5>Un enseignement de qualité</h5>
          <p>Vous apprendrez la danse avec des professeurs renommés, spécialistes dans leur discipline et prisés aux festivals internationaux. A l'écoute, en cours collectifs ou particuliers, ils sauront adapter leur pédagogie à votre profil et à vos attentes. Vous serez dans les meilleures conditions pour progresser !</p>
        </div>
      </div>
    </div>
  );
}

export default HomePresentation;
