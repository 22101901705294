import { Link } from 'react-router-dom';
import React from 'react';

function SubFooter() {
  return (
    <div className="subfooter">
      <Link to="/mentions-legales">mentions légales</Link>
      <span>Danc'in La Roseraie - 2024</span>
    </div>
  );
}

export default SubFooter;
