import React from 'react';
import img from "../../assets/planning.jpg";
import img2 from "../../assets/tarifs.png";
import planningPDF from "../../assets/planning_2024-2025.pdf";
import tarifsPDF from "../../assets/Tarifs_2024-2025-A4.pdf";

function HomePlanning() {
  return (
    <div className="home-planning">
      <h1 className="main-font">Planning de l'annee</h1>
      <small>Pour imprimer le planning, <a className="print-planning" href={planningPDF} target="_blank" rel="noreferrer">cliquez ici</a>.</small>
      <img src={img} alt="Planning 2024-2025 du Danc'in La Roseraie" style={{marginBottom: '2rem'}} />
      <h1 className="main-font">Tarifs de l'annee</h1>
      <small>Pour imprimer les tarifs, <a className="print-planning" href={tarifsPDF} target="_blank" rel="noreferrer">cliquez ici</a>.</small>
      <img src={img2} alt="Tarifs 2024-2025 du Danc'in La Roseraie" />
    </div>
  );
}

export default HomePlanning;