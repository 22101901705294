import Head from '../Shared/Head';
import Navbar from '../Shared/Navbar';
import {NavbarProvider} from '../Contexts/navbar-context';
import PriceCard from '../Utils/Cards/PriceCard';
import PriceChildCard from '../Utils/Cards/PriceChildCard';
import React from 'react';
import star1 from '../assets/star-x1.png';
import star2 from '../assets/star-x2.png';
import star3 from '../assets/star-x3.png';
import star4 from '../assets/star-x4.png';
import starFullPass from '../assets/star-full-pass.png';

function PricesPage() {

  const pricesAdult = [
    {name: '1 cours / semaine', mainPrice: '315', reducedPrice: '285', img: star1},
    {name: '2 cours / semaine', mainPrice: '420', reducedPrice: '390', img: star2},
    {name: '3 cours / semaine', mainPrice: '525', reducedPrice: '495', img: star3},
    {name: '4 cours / semaine', mainPrice: '600', reducedPrice: '570', img: star4},
    {name: 'Full Pass', mainPrice: '645', reducedPrice: '615', img: starFullPass},
  ];

  const pricesChild = [
    {name: '1 cours / semaine', mainPrice: '285', reducedPrice: '', img: star1},
    {name: '2 cours / semaine', mainPrice: '390', reducedPrice: '', img: star2},
  ];

  return (
    <div className="prices-container">
      <Head title={'Tarifs'} />
      <NavbarProvider value="dark">
          <Navbar/>
      </NavbarProvider>

      <h6 className="main-font">Tarifs 2024/2025 pour adultes</h6>
      <p className="help-text-top">Les frais d'adhésion de 20€ sont à rajouter au forfait choisi (offerts aux préinscriptions).</p>
      <div className="prices">
        {pricesAdult.map(price => {
          return (
            <PriceCard
              name={price.name}
              mainPrice={price.mainPrice}
              reducedPrice={price.reducedPrice}
              img={price.img}
              size={450}
              key={price.name}
            />
          )
        })}
      </div>
      <p className="help-text">* Tarifs réduits : Pré-inscription, étudiant de moins de 25 ans, demandeur d’emploi sur présentation d’un justificatif, inscription en couple et membres de même famille (mère-père / enfant-ado).</p>

      <h6 className="main-font">Tarifs 2024/2025 pour enfants et ados</h6>
      <p className="help-text-top">Les frais d'adhésion de 20€ sont à rajouter au forfait choisi (offerts aux préinscriptions).</p>
      <div className="prices">
        {pricesChild.map(price => {
          return (
            <PriceChildCard
              name={price.name}
              mainPrice={price.mainPrice}
              reducedPrice={price.reducedPrice}
              img={price.img}
              size={320}
              key={price.name}
            />
          )
        })}
      </div>
    </div>
	);
}

export default PricesPage; 
