import EventItem from "../Sections/Events/EventItem";
import Head from '../Shared/Head';
import Navbar from '../Shared/Navbar';
import {NavbarProvider} from '../Contexts/navbar-context'
import React from 'react';
import {withFirebase} from '../Firebase';

class EventsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            events: [],
        }
    }
    
    componentDidMount() {
        this.setState({ loading: true });
        
        this.props.firebase.getEvents().then((snapshot) => {
            let array = [];
            snapshot.forEach((ev) => array.push(ev.data()));
            this.setState({ events: array, loading: false });
        }).catch((error) => console.log("Error getting events: ", error));
    }

    render() {
        return (
            <React.Fragment>
                <Head title="Evénements" />
                <NavbarProvider value="dark">
                    <Navbar/>
                </NavbarProvider>
                <section className="events-section">
                    <h2 className="main-font title">Evenements</h2>
                    <div className="divider" />
                    <div className="events-list columns">
                        {this.state.events.map(event => <EventItem key={event.id} event={event} />)}
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default withFirebase(EventsPage);
