import React from 'react';
import TeacherCard from '../../Utils/Cards/TeacherCard';
import {teachers} from '../../Datas/teachers';

function HomeTeachers() {
  return (
    <div className="teachers-container">
      <section className="section">
        <h2 className="main-font">Nos professeurs</h2>
        <p>Pour découvrir la biographie de chaque professeur, cliquez sur le lien "voir plus" en dessous de la photo.</p>
        <div className="teacher-cards-container">
          {teachers.map(teacher => {
            return (
              <div className="teacher-card-container" key={teacher.name}>
                <TeacherCard img={teacher.coverImg} name={teacher.name} slug={teacher.slug} dances={teacher.dances} />
              </div>
            )
          })}
        </div>
      </section>
    </div>
  );
}

export default HomeTeachers;
