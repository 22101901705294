export const allDances = [
  {
      name: 'bachata',
      type: 'bachata',
      checked: true
  }, {
      name: 'kizomba',
      type: 'kizomba',
      checked: true,
  }, {
      name: 'reggaeton',
      type: 'reggaeton',
      checked: true,
  }, {
      name: 'rock',
      type: 'rock',
      checked: true,
  }, {
      name: 'salsa',
      type: 'salsa',
      checked: true,
  }, {
      name: 'tango argentin',
      type: 'tango',
      checked: true,
  }, {
      name: 'afro',
      type: 'afro',
      checked: true,
  }, {
      name: 'danse orientale',
      type: 'orientale',
      checked: true,
  }, {
      name: 'afro vibe',
      type: 'afrovibe',
      checked: true,
  }, {
      name: 'lady styling latino',
      type: 'ladystylinglatino',
      checked: true,
  }, {
      name: 'street jazz',
      type: 'streetjazz',
      checked: true,
  }, {
    name: 'dancehall',
    type: 'dancehall',
    checked: true,
  }, {
    name: 'danse de salon',
    type: 'dansesalon',
    checked: true,
  }
]