import { Helmet } from 'react-helmet';
import React from 'react';
import logo from '../assets/logo.png';

const defaultDescription = "Le Danc'in la Roseraie est une école de danse à Toulouse qui vous invite à pratiquer différentes danses: Bachata, Rock, Kizomba, Salsa Cubaine, Salsa Portoricaine, Danses de Salon, Tango Argentin et Reggaeton.";

function Head({ title, description = defaultDescription }) {
  return (
    <Helmet>
      <title>Danc'in Ecole de Danse sur Toulouse | {title}</title>
      <link rel="icon" type="image/png" href={logo} />
      <meta name="description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="https://danc-in-roseraie.com/" />
      <meta property="og:locale" content="fr_FR" />
      <meta property="og:image" content={logo} />
      <meta property="og:title" content={`Danc'in Ecole de Danse sur Toulouse | ${title}`} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:description" content={description} />
    </Helmet>
  );
}

export default Head;
