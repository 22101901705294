import { Link } from 'react-router-dom';
import React from 'react';
import SubFooter from './SubFooter';
import logo from '../assets/logo.png';

function showRegisterModal() {
  document.getElementsByClassName('r-modal')[0].classList.add('is-active');
  document.getElementsByTagName('html')[0].classList.add('is-clipped');
};

function Footer() {
  return (
    <footer>
      <div className="columns">
        <div className="column is-full-mobile is-half-tablet is-3-desktop">
          <Link to="/">
            <img src={logo} alt="" style={{width: '55%'}} />
          </Link>
        </div>
        <div className="column is-full-mobile is-half-tablet is-3-desktop">
          <h6>Liens utiles</h6>
          <ul>
            <li><Link to="/">Accueil</Link></li>
            <li><Link to="/professeurs">Professeurs</Link></li>
            <li><Link to="/planning">Planning</Link></li>
            <li><Link to="/evenements">Évènements</Link></li>
            <li><a onClick={showRegisterModal}>S'inscrire</a></li>
            <li><Link to="/tarifs">Tarifs</Link></li>
            <li><Link to="/ouverture-de-bal-de-mariage">Ouverture de bal<br/>de mariage</Link></li>
          </ul>
        </div>
        <div className="column is-full-mobile is-half-tablet is-3-desktop contact">
          <h6>Contact</h6>
          <p>135 Avenue de Lavaur, 31500 - Toulouse</p>
          <p>
            <a href="mailto:dancin.assoc@gmail.com">dancin.assoc@gmail.com</a><br/>
            <a href="tel:+33619752337">06 19 75 23 37</a>
          </p>
          <Link to="/contact">
            <button>Nous contacter</button>
          </Link>
          <p>Un mariage ? Une envie de débuter votre soirée par un moment de danse inoubliable ? N'hésitez pas à nous contacter pour votre ouverture de bal de mariage.</p>
        </div>
        <div className="column is-full-mobile is-half-tablet is-3-desktop follow">
          <h6>Nous suivre</h6>
          <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/dancinlaroseraie/">
            <i className="fab fa-facebook-square"></i>
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/dancinlaroseraie/?hl=fr">
            <i className="fab fa-instagram"></i>
          </a>
          <p>Ecole de danse sur Toulouse, proche Balma, Croix-Daurade, Bonnefoy, Marengo, Jolimont, Argoulets, Lasbordes, Quint-Fonsegrives, Montaudran, Côte pavée, Château de l'hers, Gramont, Pin-Balma.</p>
          <p style={{marginTop: '8px'}}>Danses latines, danses de salon, danses de couple, rock, salsa, bachata, kizomba, tango argentin, west coast swing, danse orientale, flamenco, afro vibe, yogadanse, lady styling latino, street jazz, reggaeton, hip-hop, dancehall.</p>
        </div>
      </div>

      <SubFooter />
    </footer>
  );
}

export default Footer;
