import React from 'react';
import RoomCard from  '../../Utils/Cards/RoomCard';
import room1 from '../../assets/salle_1.png';
import room2 from '../../assets/salle_2.png';
import room3 from '../../assets/salle_3.png';

function HomeRooms() {
  return (
    <div className="home-rooms">
      <h3 className="main-font">Les salles</h3>
      <p>
        Le Danc’In vous accueille dans un magnifique Espace Danse, d’une superficie totale de 400 m2 de parquet.<br/>
        Les cours, stages et soirées ont lieu dans trois salles indépendantes avec miroirs, insonorisées et climatisées.
      </p>
      <div className="columns">
        <div className="column">
          <RoomCard img={room1} name="Salle SENSUAL" />
        </div>

        <div className="column">
          <RoomCard img={room2} name="Salle PARADISE" />
        </div>

        <div className="column">
          <RoomCard img={room3} name="Salle AMOR" />
        </div>
      </div>
    </div>
  );
}

export default HomeRooms;
