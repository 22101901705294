import React from 'react';

const googleMapLink = "https://www.google.fr/maps/place/135+Avenue+de+Lavaur,+31500+Toulouse/@43.6205542,1.4667151,17z/data=!3m1!4b1!4m5!3m4!1s0x12aebccf229aeb85:0x712e93962f5ea7f9!8m2!3d43.6205542!4d1.4689038";

function ContactCard() {
  return (
    <div className="contact-card">
      <h4 className="main-font">contact</h4>
      <p>Pour tout renseignement complémentaire, contactez-nous directement par téléphone.</p>
      <p>Vous disposez également des transports en commun:<br/>
        Métro La Roseraie, ligne A<br/>
        Bus Ligne 1, 9 et 36
      </p>

      <div className="infos">
        <p>
          <a href="tel:+33619752337">06 19 75 23 37</a>
          <br/>
          <a href="mailto:dancin.assoc@gmail.com">dancin.assoc@gmail.com</a>
        </p>
        <div className="divider"></div>
        <p>
          <a href={googleMapLink} target="_blank" rel="noreferrer noopener">
            135 Avenue de Lavaur<br/>31500 Toulouse
          </a>
        </p>
      </div>
    </div>
  );
}

export default ContactCard;
