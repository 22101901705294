import React from 'react';
import { Link } from 'react-router-dom';

function CourseCard({ name, img }) {
  return (
    <div className="course-card" style={{backgroundImage: `url(${img})`}}>
      <button className="bottom-card-btn">
        <Link to="/planning">
          {name}
        </Link>
      </button>
    </div>
  );
}

export default CourseCard;
