import Head from '../Shared/Head';
import HomeCourses from '../Sections/Home/HomeCourses';
import HomeHistory from '../Sections/Home/HomeHistory';
import HomePlanning from '../Sections/Home/HomePlanning';
import HomePresentation from '../Sections/Home/HomePresentation';
import HomeRooms from '../Sections/Home/HomeRooms';
import HomeSlider from '../Sections/Home/HomeSlider';
import HomeTeachers from '../Sections/Home/HomeTeachers';
import Navbar from '../Shared/Navbar';
import {NavbarProvider} from "../Contexts/navbar-context";
import React from 'react';

// import HomeOpenHouseWeek from '../Sections/Home/HomeOpenHouseWeek';     


function HomePage() {
    return (
        <React.Fragment>
            <Head title={'Accueil'}/>
            <div className={'home-page'}>
                <NavbarProvider value="dark">
                    <Navbar/>
                </NavbarProvider>
            </div>
            <HomeSlider/>
            {/* <HomeOpenHouseWeek/> */}
            <HomeHistory/>
            <HomePresentation/>
            <HomePlanning/>
            <HomeTeachers/>
            <HomeCourses/>
            <HomeRooms/>
        </React.Fragment>
    );
}

export default HomePage;
