import {NavbarContext, NavbarThemes} from '../Contexts/navbar-context';

import {Link} from "react-router-dom";
import React from 'react';

export default class Navbar extends React.Component {
    static contextType = NavbarContext;

    menuStyle = {
        backgroundColor: NavbarThemes[this.context].background,
        color: NavbarThemes[this.context].color
    };

    state = {
        showMenu: false,
    };

    showRegisterModal = () => {
        document.getElementsByClassName('r-modal')[0].classList.add('is-active');
        document.getElementsByTagName('html')[0].classList.add('is-clipped');
    };

    render() {
        const {showMenu} = this.state;
        const classBurger = `navbar-burger burger ${showMenu && 'is-active'}`;
        const classMenu = `navbar-menu ${showMenu && 'is-active'}`;

        return (
            <nav className="navbar"
                 style={this.menuStyle}
                 role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <Link to={'/'} className="navbar-item">
                        <h1 className="main-font">Danc'in</h1>
                    </Link>

                    <a role="button" onClick={() => this.setState({showMenu: !showMenu})} className={classBurger}
                       aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>

                <div className={classMenu} style={this.menuStyle}>
                    <div className="navbar-end">
                        <Link className="navbar-item" to="/professeurs">Professeurs</Link>
                        <Link className="navbar-item" to="/planning">Planning</Link>
                        <Link className="navbar-item" to="/evenements">Évènements</Link>
                        <a onClick={this.showRegisterModal} className="navbar-item">S'inscrire</a>
                        <Link className="navbar-item" to="/tarifs">Tarifs</Link>
                        <Link className="navbar-item" to="/contact">Contact</Link>
                    </div>
                </div>
            </nav>
        );
    }
}
