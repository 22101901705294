import Head from '../Shared/Head';
import Navbar from "../Shared/Navbar";
import {NavbarProvider} from "../Contexts/navbar-context";
// import PlanningContent from "../Sections/Planning/Content/PlanningContent";
// import PlanningHeader from "../Sections/Planning/Header/PlanningHeader";
// import PriceModal from "../Sections/Planning/Modals/PriceModal";
import React from 'react';
import {allDances} from "../Datas/dances";
import planning from "../assets/planning.jpg";

// import PlanningFooter from "../Sections/Planning/Content/PlanningFooter";

export default class PlanningPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            counter: 0,
            dances: allDances,
        };

        this.handlePlanningToggle = this.handlePlanningToggle.bind(this)
    }

    handlePlanningToggle(el) {
        const {dances} = this.state;
        const index = dances.findIndex(value => value.name.toLowerCase() === el.name.toLowerCase());
        this.setState((prevState) => {
            const ng = prevState.dances;
            ng[index].checked = el.checked;
            return ({dances: ng})
        })
    }

    render() {
        const {counter} = this.state;
        return (
            <div>
                <Head title={'Planning'}/>
                <NavbarProvider value="dark">
                    <Navbar/>
                </NavbarProvider>
                <section className={"planning"}>
                    {/* <PlanningHeader dances={this.state.dances} counter={counter} onFilterToggle={this.handlePlanningToggle}/>
                    <PlanningContent dances={this.state.dances} onSelectedCourseChange={e => this.setState({counter: e})}/> */}
                    { /*<PlanningFooter />*/ }
                    <img src={planning} alt="Planning 2024-2025 du Danc'in La Roseraie" style={{margin: 'auto', display: 'block', paddingTop: '4vh'}} />
                </section>
                {/* <PriceModal counter={counter}/> */}
            </div>
        );
    }
}
