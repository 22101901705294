import Head from '../Shared/Head';
import Navbar from '../Shared/Navbar';
import {NavbarProvider} from '../Contexts/navbar-context'
import React from 'react';

function MentionsPage() {
  return (
    <React.Fragment>
      <Head title={'Mentions légales'} />
      <NavbarProvider value="dark">
        <Navbar/>
      </NavbarProvider>
      
      <div className="mentions">
        <div className="card">
          <header className="card-header card-header-title">
            Mentions légales
          </header>
          <div className="card-body">
            <div className="card-content">
              <h6>Gérants du site</h6>
              <p>Rolland Salerno, propriétaires du site et gérants de l'association Danc'in la Roseraie.</p>

              <h6>Collecte de données</h6>
              <p>Sur le site du Danc'in la Roseraie, aucune donnée n'est stockée, récoltée ou utilisée. Aucun cookie ni stockage d'informations en base de données n'est effectué.</p>
              
              <h6>Hébérgement</h6>
              <p>Hébérgeur <a href="https://ipfixe.info/" target="_blank" rel="noreferrer">IPFIXE</a> (anciennement EuroWh).</p>

              {/* <h6>Développement du site</h6>
              <p>Alexandre ROUSSANGE et Valentin STEFANESCU, développeurs en freelance</p> */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default MentionsPage;
