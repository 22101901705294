import alainKaterina from '../assets/alainKaterina.jpg';
import alainKaterinaDanse from '../assets/alainKaterinaDanse.jpg';
import andrea from '../assets/andrea.jpeg';
import andreaDanse from '../assets/andreaDanse.jpeg';
import bastienEtMorgane from '../assets/bastienEtMorgane.jpg';
import bastienMorganeDanse from '../assets/bastienMorganeDanse.jpg';
import christineLasserre from '../assets/christineLasserre.jpg';
import claude from '../assets/claude.JPG';
import claudeDanse from '../assets/afro.jpg';
import davinaDanse from '../assets/heels.JPG';
import elisa from '../assets/elisa.jpg';
import izzy from '../assets/izzy.jpeg';
import javi from '../assets/javi.jpg';
import javiDanse from '../assets/dominicaine.jpg';
import juanMeryl from '../assets/juanMeryl.jpeg';
import juanMerylDanse from '../assets/juanMerylDanse.jpg';
import marinaEtBassem from '../assets/marinabassem.jpg';
import maya from '../assets/maya.jpeg';
import mayaDanse from '../assets/mayaDanse.PNG';
import rolland from '../assets/rolland.jpg';
import russelMaeva from '../assets/russelMaeva.JPG';
import stephanie from '../assets/stephanie.jpeg';
import stephanieDanse from '../assets/stephanieDanse.jpeg';
import teamMarinaCarranzaDanse from '../assets/teamMarinaCarranzaDanse.JPG';
import yordanis from '../assets/yordanis.jpg';

export const teachers = [
  {coverImg: rolland, img: rolland, name: 'Roland Salerno', slug: 'roland-salerno', dances: 'Rock, Danse de Salon'},
  {coverImg: bastienEtMorgane, img: bastienMorganeDanse, name: 'Bastien & Morgane', slug: 'bastien-morgane', dances: 'Bachata Fusion, Bachata Footwork'},
  {coverImg: yordanis, img: yordanis, name: 'Yordanis Aguero Delgado', slug: 'yordanis-aguero-delgado', dances: 'Salsa Cubaine, Reggaeton'},
  {coverImg: marinaEtBassem, img: teamMarinaCarranzaDanse, name: 'Team Marina Carranza', slug: 'team-marina-carranza', dances: 'Tango Argentin'},
  {coverImg: izzy, img: izzy, name: 'Izzy', slug: 'izzy', dances: 'Afro Vibe, Dancehall'},
  {coverImg: stephanie, img: stephanieDanse, name: 'Stéphanie', slug: 'stephanie', dances: 'Kizomba Traditionnelle'},
  {coverImg: maya, img: mayaDanse, name: 'Maya', slug: 'maya', dances: 'Danse Orientale'},
  {coverImg: javi, img: javiDanse, name: 'Javi', slug: 'javi', dances: 'Bachata Dominicaine'},
  {coverImg: elisa, img: elisa, name: 'Elisa', slug: 'elisa', dances: 'Pilates'},
  {coverImg: christineLasserre, img: christineLasserre, name: 'Christelle Lasserre', slug: 'christelle-lasserre', dances: 'Street Jazz'},
  {coverImg: juanMeryl, img: juanMerylDanse, name: 'Juan & Meryl', slug: 'juan-meryl', dances: 'Bachata'},
  {coverImg: claude, img: claudeDanse, name: 'Claude Gomis', slug: 'claude', dances: 'Afro Dance'},
  {coverImg: russelMaeva, img: russelMaeva, name: 'Russel & Maêva', slug: 'russel-maeva', dances: 'Salsa Portoricaine'},
  {coverImg: andrea, img: andreaDanse, name: 'Andréa', slug: 'andrea', dances: 'Heels'},
  {coverImg: alainKaterina, img: alainKaterinaDanse, name: 'Alain & Katerina', slug: 'alain-katerina', dances: 'Salsa Cubaine, Son Cubain'},
];

export const teachersContent = [
  {'roland-salerno': {
    name: 'Roland Salerno',
    img: rolland,
    dances: 'Rock, Danse de Salon',
    text: [
      "Directeur artistique, manager et responsable de l'équipe enseignante, danseur depuis son plus jeune âge, Roland Salerno, votre professeur de Rock, danses de salon et danses sportives, ex champion de France en danses Latines et vice-champion en danses Standard, se voit récemment décerner le titre de Maître de danse. Pendant, dix ans, il enseigne la danse en Espagne où il peaufine ses compétences en Latino.",
      "Doté d'un sens aigu du rythme et de la pédagogie, il sait mettre sa longue expérience de l'enseignement au service de sa passion. A l'aise dans la transmission, il veille à l'équilibre et à la cohérence de ses cours, encourage votre progression en adaptant son enseignement à différents niveaux, dans le respect d'un climat de confiance et de bienveillance.",
      "Comme une évidence, le désir de vous faire danser s'impose à lui...",
    ],
    trophies: [
      "Champion de France de Danse de Salon",
      "Titre de Maître de Danse",
    ],
  }},
  {'bastien-morgane': {
    name: 'Bastien & Morgane',
    img: bastienMorganeDanse,
    dances: 'Bachata Fusion & Bachata Footwork',
    text: [
      "Bastien et Morgane sont deux danseurs passionnés et dans le milieu depuis 2015. Ils se sont rencontrés en 2012 Morgane et commencèrent un partenariat ensemble. Depuis leur rencontre, ils enchaînent festivals et stages avec les meilleurs artistes du milieu (champions de France, champions du monde, artistes en vogue...) afin de se perfectionner en Bachata. Ils ont également participé à de nombreux concours et ont finalement été sacré champions de France en Bachata Fusion en 2018 À Marseille.",
      "Ils développèrent rapidement leur style alliant l'urbain et le traditionnel imprégné de sensualité. Humour, écoute et pédagogie sont leur maître-mot pendant leur cours.",
    ],
    trophies: [
      "Champion de France (Bachatart 2018)",
      "Vice Champion de France FFD Bachata Homme Solo (2021)",
      "Vice Champion de France UBOF Compétition Catégorie 'Battle Men Solo' (2019)",
      "Champion Team Bachata Spain",
      "Vice-Champion Bachata Soliste World Bachata Cup (2024)",
    ],
  }},
  {'yordanis-aguero-delgado': {
    name: 'Yordanis Aguero Delgado',
    img: yordanis,
    dances: 'Salsa Cubaine, Reggaeton',
    text: [
      "Yordanis est né à Santiago de Cuba, la Tierra Caliente, berceau du Son. Dès l’âge de 6 ans, il fait ses premiers pas dans les Danses Populaires et Traditionnelles de son pays, parmi lesquelles le Pilon, le Mambo, le Mozambique, le ChaCha, le Son. Il intégrera par la suite une troupe de danse pour parfaire son savoir et apprendre de nouvelles danses. Suscitant l’intérêt du public, il intégrera à l’âge de 16 ans, la très prestigieuse Ecole Du Tropicana de Santiago de Cuba en tant que professionnel.",
      "C’est alors qu’il rejoint la troupe de Rueda de Casino de l’actuel champion du monde de salsa Yanek REVILLA : «Casino.com». Avec cette troupe il remportera de nombreux titres dont l’un des plus prestigieux : Champion du Monde 2005. Yordanis est aujourd’hui installé en France où il dispense ses cours de Salsa, Rueda, Suelta, Reggaeton, Rumba et AfroCubain avec passion.",
    ],
    trophies: [
      "Champion du monde 2005 avec la troupe Rueda de Casino",
    ],
  }},
  {'team-marina-carranza': {
    name: 'Team Marina Carranza',
    img: teamMarinaCarranzaDanse,
    dances: 'Tango Argentin',
    text: [
      "La compagnie AQUÍ Macorina spécialisée en tango argentin depuis plus de 15 ans dans le sud de la France enseigne désormais au Danc'In afin de vous faire découvrir cette danse ou l' améliorer.",
      "Marina Carranza, artiste chorégraphe et pédagogue de renommée internationale, est la directrice de la 'Huella Tango'. Venez découvrir ou perfectionner l'une de plus belle danse de couple dirigée d' une main de maitre par :",
      "Irene Messina (Buenos Aires), Serguei Pavlenko (Russie) et Fernanda Seaone (Buenos Aires) et Marina Carranza (Buenos Aires) Excellence pédagogique, et excellente ambiance dans la richesse de la tradition et la culture du tango du Rio de la Plata."
    ],
    trophies: [],
  }},
  {'izzy': {
    name: 'Izzy',
    img: izzy,
    dances: 'Afro Vibe',
    text: [
      "Izzy est une danseuse d’ Afro et de Dancehall. Elle donne des cours d’ Afrovibe depuis de nombreuses année danseuse dans les écoles les plus prestigieuses de la région Toulousaine. Elle a décidé d’exercer au Danc’In et nous en sommes ravis.",
      "L’Afrovibe est est une danse afro/caribéenne imprégnée de fitness avec une partie renforcement, cardio et chorégraphique. Accessible à tous, les mouvements sont toujours dansés en rythme, genoux fléchis et dans le sol. Elle saura vous faire partager sa passion et sa culture dans une ambiance très conviviale et remplie de soleil.",
    ],
    trophies: [],
  }},
  {'stephanie': {
    name: 'Stéphanie',
    img: stephanieDanse,
    dances: 'Kizomba Traditionnelle',
    text: [
      "Passionnée de danse depuis toujours et surtout les rythmes afro-caribéen, Stephanie découvre la Kizomba en 2013 et tombe amoureuse de cette danse, depuis elle a participée à de nombreux événements et à développée son propre style.",
      "Professeur de kizomba depuis 2015 elle donne des cours particuliers de lady-styling, puis en 2016 des cours collectifs de kizomba, semba (formation professera en 2016).",
      "Depuis elle continue de perfectionner sa pédagogie en Leader comme Follower pour pouvoir vous faire évoluer de plus en plus dans la joie, la bonne humeur et la simplicité !"
    ],
    trophies: [],
  }},
  {'maya': {
    name: 'Maya',
    img: mayaDanse,
    dances: 'Danse Orientale',
    text: [
      "Ancienne élève du professeur de Danse Orientale Marion Richard de Toulouse, Maya partage pleinement sa passion avec ses élèves en enseignant sa technique et son propre style. Elle continue à peaufiner sa danse en participant régulièrement à des stages avec des grands maitres de la danse orientale en Egypte par exemple. Danseuse professionnelle  et Chorégraphe, Maya saura vous transmettre son amour pour cette danse  qui se base par la dissociation des parties du corps (isolations) :ventre ,bassin, cage thoracique et bras.",
      "Elle vous donnera les clés afin que votre corps puisse  exprimer vos sentiments, avec des mouvements qui  se caractérisent par des vibrations, ondulations accompagnées par la musique. Maya s'accompagnera des accessoires nécessaire pour cette danse comme le voile, la canne, melaya, shamadan, les ailes d Isis, les bougies et autres... Cette danse est accessible a tous quelque soit l' âge ou la morphologie de chacun dans une ambiance toujours très conviviale !"
    ],
    trophies: [],
  }},
  {'javi': {
    name: 'Javi',
    img: javiDanse,
    dances: 'Bachata Dominicaine',
    text: [
      "Javi est un danseur passionné d'origine espagnol. Il découvre la bachata dominicaine à Toulouse après avoir tester plusieurs danses latines (comme la salsa et d' autres styles de bachata). Il tombe rapidement amoureux de la musique ainsi que de la culture des danses dominicaines. Après 6 mois de formation en Bachata Dominicaine, il gagne le premier prix dans une compétition amateur à Bordeaux. Il décide alors de se former également comme professeur avec des grands maitres de Bachata Dominicaine d' un niveau international, en faisait jusqu'à trois formations afin de développer et construire sa pédagogie. Il commence donc à enseigner sur Toulouse et devient finaliste dans la World Traditional Bachata Competition lors du festival Dominican Swag. Il décide donc de voyager en République Dominicaine pour s'imprégner du flow des danseurs de l'île. Il est désormais très sollicité en France comme à l' étranger et cette année il sera au Danc'In pour vous faire partager se passion."
    ],
    trophies: [],
  }},
  {'elisa': {
    name: 'Elisa',
    img: elisa,
    dances: 'Pilates',
    text: [
      "Vous souhaitez reprendre le sport après une période d’inactivité, compléter votre pratique sportive, ou bien soulager des problèmes de dos, d’articulations… ou encore retrouver une meilleure posture (grâce à l’engagement des abdominaux profonds tout au long de la pratique) ou... bouger tout simplement ? Quel que soit votre âge, votre morphologie ou votre mode de vie le Pilates c’est pour tout le monde !",
      "Elisa vous propose des cours tous niveaux dans la bienveillance et la bonne humeur où nous viendrons chercher le travail des muscles profonds en se concentrant sur la respiration Pilates, ce qui permet également un recentrage et de lutter contre le stress, l’anxiété ou encore les troubles du sommeil… N’hésitez plus, le Pilates c’est la santé !"
    ],
    trophies: [],
  }},
  {'christelle-lasserre': {
    name: 'Christelle Lasserre',
    img: christineLasserre,
    dances: 'Street Jazz',
    text: [
      "Elle ne savais pas marcher que déjà elle dansais !!! Très vite son rêve fut de devenir danseuse et de partager cette passion avec le plus grand nombre !",
      "Son parcours débute en danse classique, et oui en tutu, en suivant les enseignements de l'école de danse classique Princesse Grace à Monaco. Puis lors de son entrée en formation au conservatoire pour préparer son DE, la révélation pour elle : Le Moderrn Jazz..puis la Street Jazz.",
      "A partir de là de merveilleuses portes s'ouvrent à elle pour danser dans différentes comédies musicales, spectacles...et commencer à enseigner dans différentes écoles sur Toulouse et ses environs, en Moderrn jazz, Street jazz et hip hop.Elle est vraiment ravie d'intégrer la team du Danc'In."
    ],
    trophies: [],
  }},
  {'juan-meryl': {
    name: 'Juan & Meryl',
    img: juanMerylDanse,
    dances: 'Bachata',
    text: [
      "Juan et Meryl sont des danseurs de Bachata reconnus qui pratiquent cette danse depuis presque 15 ans et participent à plusieurs compétitions.",
      "En plus de leur succès en tant que compétiteurs, Juan et Meryl sont également réputés pour être d'excellents pédagogues. Leur expérience approfondie de la danse et leur capacité à communiquer efficacement avec leurs élèves en font des professeurs appréciés. Ils ont consacré de nombreuses années à partager leur passion et à aider d'autres danseurs à améliorer leurs compétences et à découvrir leur propre style.",
      "En somme, Juan et Meryl sont des danseurs de Bachata talentueux et passionnés. Leur dévouement et leur impact positif sur la communauté de danseurs les ont solidement établis en tant que figures influentes dans le monde de la Bachata."
    ],
    trophies: [
      "3ème au Bachatart 2015",
      "2ème au Bachatastars 2015",
      "2ème au Paris Bachata Festival 2015"
    ],
  }},
  {'claude': {
    name: 'Claude Gomis',
    img: claudeDanse,
    dances: 'Afro Dance',
    text: [
      "Originaire du Sénégal, Claude Marius GOMIS, alias BoyCo cultive une passion pour la musique et les danses hip hop. Autodidacte, il s’adonne à la fois au break dance, son style de prédilection, mais aussi aux danses afro-urbaines (kuduro, ndombolo, coupé décalé, azonto ...) et traditionnelles africaines comme le Sabar.",
      "Claude se produit au Sénégal avec ses premières compagnies, participe à des concours, tourne des clips vidéo puis rejoint la compagnie Jant-Bi de la prodigieuse Ecole des Sables de Toubab Dialaw (Sénégal).",
      "Parallèlement, en 2013, il enseigne le Sabar au Danstheater d’Amsterdam, tout en préparant War and Peace, pièce pour 7 danseurs, mêlant danses traditionnelles africaines, contemporaines et danses urbaines. Puis, en 2O14, à l’occasion d’une pièce afro-contemporaine, il fait la page de couverture du New York Times.",
      "Lors de son installation en France, en 2015, Claude intègre la compagnie Funky Swing Dancers et participe au aux Savoy Contest et au festival international de Montpellier ou il est souvent primé en solo comme en couple. Occasionnellement, il retourne à Amsterdam pour enseigner le hip-hop, les danses afro-urbaines et le Sabar.",
      "Dans son approche pédagogique, il ne sélectionne pas : il fouille, intègre, mixe, tord, consolide et fusionne. Quelle que soit la discipline, son enseignement, à la fois instinctif et précis, axé sur les fondamentaux des danses de son continent natal, restitue son inclination pour le mélange des genres. Selon ses dires, il adopte la démarche d’un « gourmand » ..."
    ],
    trophies: [],
  }},
  {'russel-maeva': {
    name: 'Russel & Maêva',
    img: russelMaeva,
    dances: 'Salsa Portoricaine',
    text: [
      "Russel, professeur et chorégraphe de salsa, est l'un des pionniers de la salsa portoricaine (On1, On2) sur Toulouse depuis plus de 16 ans. La plupart des premiers danseurs de salsa portoricaine on-2 sur Toulouse ont débuté dans ses cours. Il a contribué également à la formation de certains professionnels de danse (professeur et artiste). Russel et Maëva donnent des cours ensemble depuis 2014. Ce sont de véritables passionnés de danses et de cultures latines. Ils ont participé à de nombreux congrès de danse à travers le monde pour offrir le meilleur enseignement possible à leurs élèves (Paris, Milan, New-York, Berlin, Varsovie, Plovdiv, Istanbul, Sibérie, Marrakech, Londres, Split…). Ils ont acquis une véritable expérience de l’enseignement (autant pour les adultes que pour les enfants) et sont réputés pour leur pédagogie et leur méthode de travail pour permettre à chacun de trouver sa voie ! Leur souhait est de faciliter la maîtrise des fondamentaux permettant, à chacun, d’exprimer sa propre personnalité.",
      "Maëva a commencé par faire du modern jazz et a grandi dans la musique latine dès son plus jeune âge. Elle a été entièrement formée à Latin’Clave. Russel son mentor, lui a appris toutes les bases essentielles de la salsa, sa passion. Elle est montée sur scène, a fait de nombreux congrès de danse à travers le monde (Paris, Francfort, Milan, Varsovie, New-York, Plovdiv, Madrid, Londres, Berlin…) pour continuer d’apprendre auprès des meilleurs danseurs(e)s comme Eddie Torres MamboKing et bien d'autres. Elle donne des cours de salsa avec Russel, son partenaire depuis 2014. Elle est professeur et chorégraphe pour adultes ainsi que pour les enfants (qu'elle dirige depuis plus de 5 ans). Grâce à ces années d'expérience et sa pédagogie, elle saura vous guider pour apprendre le lady styling et exprimer vos propres émotions dans la joie et la bonne humeur. Que vous soyez débutante ou plus avancée, ce cours vous permettra de vous sentir bien pour avoir des bases solides, afin de développer votre sens artistique et votre technique (tours, isolations, balance du corps, appuis...) en solo ou en couple ! Sa devise: 'la danse est belle et vraie, c’est le reflet de notre âme, on ne peut pas lui mentir'."
    ],
    trophies: [],
  }},
  {'andrea': {
    name: 'Andréa',
    img: andreaDanse,
    dances: 'Heels',
    text: [
      "Il commence à se former à l’âge de six ans au Conservatoire de Danse de Roussillon en Danse Classique et Jazz. Il continue sa formation dans des styles de danse plus urbaines au sein de l’association Danse La Plaine Entente dirigée par Claire Patch. Il a ensuite validé son examen d'Aptitudes Techniques au Lyon Art Danse Center en 2020 et il a poursuivit sa formation au centre James Carles à Toulouse pour valider son Diplôme d’Etat en Danse Jazz. Toujours animé par les danses urbaines et académiques, il met un point d’honneur à valoriser la technique, l’évolution et surtout la passion de la danse.",
      "« La danse pour moi a toujours été un moyen d’expression mais aussi une safe place dans laquelle je me suis toujours senti bien. Un professeur m’a dit un jour : -les problèmes dans la vie tu en auras toujours mais le pouvoir d’une salle de danse c’est que pendant 1H30 tu les laisses derrière la porte. -»",
      "La danse a le pouvoir de faire grandir et d’affronter la vie avec une touche d’artistique qui, pour moi, est fondamentale »"
    ],
    trophies: [],
  }},
  {'alain-katerina': {
    name: 'Alain & Katerina',
    img: alainKaterinaDanse,
    dances: 'Salsa Cubaine & Son Cubain',
    text: [
      "Alain et Katerina, ce jeune couple d’ instructeurs diplômés de danse, sont basés à Anglet où ils enseignent avec brio la Salsa Cubaine, La Rumba Cubaine, le Son Cubain et la danse afro-cubaine.",
      "Prisés à l'international , Alain (franco Cubain) et  Katerina (Franco Russe) forment un duo explosif qui permettra aux initiés d’ améliorer leur danse sans aucun doute ! En effet, ce sont deux pointures dans leur domaine !",
      "Malgré la distance qui nous sépare, ils feront partie de l’équipe de profs à la rentrée et donneront 2 heures de cours tous les mercredis au Danc’In. La Salsa cubaine dans toute sa splendeur et diversité !"
    ],
    trophies: [],
  }}
];