import React from 'react';

function PriceCard({name, mainPrice, reducedPrice, img, size}) {
  return (
    <div className="price-card" style={{ minHeight: `${size}px` }}>
      <h2>{name}</h2>
      <img src={img} alt="" />
      <div>
        <p>Tarif plein</p>
        <span>{mainPrice} €</span>
        <div className="divider"></div>
        <p>Tarif réduit *</p>
        <span>{reducedPrice} €</span>
      </div>
    </div>
  );
}

export default PriceCard;
