import { collection, getDocs, getFirestore } from "firebase/firestore";

import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyDMWKUpxTz3Q5gyVXkafADAMn-j-HakD38",
  authDomain: "dancin-test.firebaseapp.com",
  databaseURL: "https://dancin-test.firebaseio.com",
  projectId: "dancin-test",
  storageBucket: "dancin-test.appspot.com",
  messagingSenderId: "818347557098",
  appId: "1:818347557098:web:f971e6090914ccca185793",
};

export default class Firebase {
  constructor() {
    this.state = {
      app: initializeApp(firebaseConfig),
      db: getFirestore(initializeApp(firebaseConfig))
    }
  }

  getSlides = () => {
    return getDocs(collection(this.state.db, 'slides'));
  }

  getEvents = async () => {
    return getDocs(collection(this.state.db, 'events'));
  }
}