import React from "react";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
};

class ContactFormCard extends React.Component {
  constructor() {
    super();
    this.state = {
      nom: "",
      prenom: "",
      telephone: "",
      mail: "",
      message: "",
      errors: {
        nom: "",
        prenom: "",
        telephone: "",
        mail: "",
        message: "",
      },
    };

    this.getMailTo.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
  }

  isFormValid() {
    const {errors, nom, prenom, telephone, mail, message} = this.state;
    return validateForm(errors) && nom.length && prenom.length && telephone.length && mail.length && message.length;
  }

  getMailTo() {
    const message = `${this.state.message} %0D%0A %0D%0A ${this.state.nom} ${this.state.prenom} %0D%0A %0D%0A ${this.state.telephone} - ${this.state.mail}`;
    return `mailto:dancin.assoc@gmail.com?body=${message}&subject=Contact depuis le site du Dancin`;
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const errors = this.state.errors;

    switch (name) {
      case "nom":
        errors.nom =
          value.trim().length < 3
            ? "Votre nom doit avoir 3 caractères au moins"
            : "";
        break;
      case "prenom":
        errors.prenom =
          value.trim().length < 3
            ? "Votre prenom doit avoir 3 caractères au moins"
            : "";
        break;
      case "telephone":
        errors.telephone =
          value.trim().length !== 10
            ? "Le numéro de téléphone doit être de 10 caractères"
            : "";
        break;
      case "mail":
        errors.mail = this.validerEmail()
            ? "Votre adresse mail n'est pas valide"
            : "";
        break;
      case "message":
        errors.message =
          value.length < 50 ? "Votre message doit contenir minimum 50 caractères" : "";
        break;
      default:
        break;
    }

    this.setState({
      [name]: value,
    });
  }

  validerEmail() {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(this.state.mail);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    validateForm(this.state.errors) ? console.info("Valid Form") : console.error("Invalid Form");
  };

  render() {
    return (
      <div className="contact-card contact-card-form">
        <h4 className="main-font">Formulaire de contact</h4>
        <form className="columns">
          <div className="column is-10 is-offset-1">
            <div className="columns">
              <div className="control column">
                <input
                  className="input"
                  type="text"
                  name="nom"
                  placeholder="Nom"
                  value={this.state.nom}
                  onChange={this.handleInputChange}
                />
                {this.state.errors['nom'] ? <p className="help is-danger">{this.state.errors["nom"]}</p> : ''}
              </div>
              <div className="control column">
                <input
                  className="input"
                  type="text"
                  name="prenom"
                  placeholder="Prenom"
                  value={this.state.prenom}
                  onChange={this.handleInputChange}
                />
                {this.state.errors['prenom'] ? <p className="help is-danger">{this.state.errors["prenom"]}</p> : ''}
              </div>
            </div>
            <div className="columns">
              <div className="control column">
                <input
                  className="input"
                  type="text"
                  name="telephone"
                  placeholder="Téléphone"
                  value={this.state.telephone}
                  onChange={this.handleInputChange}
                />
                {this.state.errors['telephone'] ? <p className="help is-danger">{this.state.errors["telephone"]}</p> : ''}
              </div>
              <div className="control column">
                <input
                  className="input"
                  type="text"
                  name="mail"
                  placeholder="Adresse mail"
                  value={this.state.mail}
                  onChange={this.handleInputChange}
                />
                {this.state.errors['mail'] ? <p className="help is-danger">{this.state.errors["mail"]}</p> : ''}
              </div>
            </div>
            <div className="control">
              <textarea
                className="textarea"
                placeholder="Message"
                rows="10"
                name="message"
                value={this.state.message}
                onChange={this.handleInputChange}
              ></textarea>
                {this.state.errors['message'] ? <p className="help is-danger">{this.state.errors["message"]}</p> : ''}
            </div>
            <div className="buttons aligner">
              <a className="button is-warning" disabled={!this.isFormValid()} href={this.getMailTo()}>
                Envoyer
              </a>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default ContactFormCard;
