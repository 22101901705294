import React from 'react';

function formatDate(date) {
    const d = date.toDate();
    return `${d.getDate() < 10 ? '0'+d.getDate() : d.getDate()}/${d.getMonth() < 9 ? '0'+(d.getMonth()+1) : d.getMonth()+1}/${d.getFullYear()}`;
}

function EventItem({event}) {
    return (
        <div className="column is-full-mobile is-half-tablet is-one-third-desktop">
            <a href={event.link} target="_blank">
                <div className="card event-item">
                    <div className="card-image">
                        <figure className="image is-4by3">
                            <img src={event.image} alt={event.name} />
                        </figure>
                    </div>
                    <div className="card-content">
                        <div className="content" style={{textAlign: 'center'}}>
                            <span>{event.name}</span> - <span>{formatDate(event.date)}</span>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    )
}

export default EventItem;