import { Route, Switch } from 'react-router-dom';

import ContactPage from './Pages/ContactPage';
import EventsPage from './Pages/EventsPage';
import Footer from './Shared/Footer';
import HomePage from './Pages/HomePage';
import MentionsPage from './Pages/MentionsPage';
import PlanningPage from './Pages/PlanningPage';
import PricesPage from './Pages/PricesPage';
import React from 'react';
import RegisterModal from './Utils/RegisterModal';
import TeacherPage from './Pages/TeacherPage';
import TeachersPage from './Pages/TeachersPage';
import WeddingLink from './Shared/WeddingLink';
import WeddingPage from './Pages/WeddingPage';

function App() {
  return (
    <React.Fragment>
      <main>
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path='/planning' exact component={PlanningPage} />
          <Route path='/tarifs' exact component={PricesPage} />
          <Route path='/contact' exact component={ContactPage} />
          <Route path='/evenements' exact component={EventsPage} />
          <Route path='/mentions-legales' exact component={MentionsPage} />
          <Route path="/ouverture-de-bal-de-mariage" component={WeddingPage} />
          <Route path='/professeurs' exact component={TeachersPage} />
          <Route path="/professeurs/:professeur" component={TeacherPage} />
        </Switch>

        <RegisterModal />

        <WeddingLink />
      </main>
      
      <Footer />
  </React.Fragment>
  );
}

export default App;
