import {Link} from "react-router-dom";
import React from 'react';

function WeddingLink() {
  return (
    <div className="wedding-link">
      <Link to="/ouverture-de-bal-de-mariage">
        Ouverture de<br />bal de mariage
      </Link>
    </div>
  );
}

export default WeddingLink;